/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; height: 100%; overflow: hidden; color: white}
html {
  --scrollbarBG: rgba(221,221,221,0.2);
  --thumbBG: rgba(221,221,221,0.2);
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
* {
  scrollbar-width: none;
  scrollbar-color: transparent var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 2px;
}

.margin-8 {
  margin: 8px;
}
.margin-16 {
  margin: 16px;
}
.margin-32 {
  margin: 32px;
}

.padding-8 {
  padding: 8px;
}
.padding-16 {
  padding: 16px;
}
.padding-32 {
  padding: 32px;
}

.margin-left-8 {
  margin-left: 8px;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-left-32 {
  margin-left: 32px;
}

.padding-left-8 {
  padding-left: 8px;
}
.padding-left-16 {
  padding-left: 16px;
}
.padding-left-32 {
  padding-left: 32px;
}

.margin-right-8 {
  margin-right: 8px;
}
.margin-right-16 {
  margin-right: 16px;
}
.margin-right-32 {
  margin-right: 32px;
}

.padding-right-8 {
  padding-right: 8px;
}
.padding-right-16 {
  padding-right: 16px;
}
.padding-right-32 {
  padding-right: 32px;
}

.margin-top-8 {
  margin-top: 8px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-top-32 {
  margin-top: 32px;
}

.padding-top-8 {
  padding-top: 8px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-top-32 {
  padding-top: 32px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-bottom-16 {
  margin-bottom: 16px;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}
.padding-bottom-16 {
  padding-bottom: 16px;
}
.padding-bottom-32 {
  padding-bottom: 32px;
}

.align-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.button-size-200 {
  width: 200px;
  display: inline-block;
}

.actionable {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.scrollable {
  flex: 1;
  overflow-y: scroll;
}

.inline-flex {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1;
}

.full-height .scrollable-content {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 80px;
}

.flex-wide {
  flex: 1
}

.scroll-horizontal {
  overflow-x: scroll;
}

.full-width {
  width: 100%
}

.inner-section {
  background-color: #1c2025;
  border-radius: 4px;
}

.inner-section .sections{
  background-color: #282c34;
}

.scroll-vertical {
  overflow-y: scroll;
}

.item {
  position: relative;
}

.flex-row {
  display: flex;
}

.box {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  min-width: 250px;
}

@media (max-width: 600px) {
  .responsive-dialog {
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
  }
}
