// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

@function modify-background($theme, $config) {
  // Replace the background in the color object's background palette.
  $theme-color: map-get($theme, color);
  $color-background-palette: map-get($theme-color, background);
  $color-background-palette: map-merge($color-background-palette, $config);

  // Replace the background in the background palette.
  $background-palette: map-get($theme, background);
  $color-background-palette: map-merge($color-background-palette, $config);

  // Merge the changes into a new theme.
  $modified-theme-color: map-merge($theme-color, (background: $color-background-palette));
  $modified-theme: map-merge($theme, (color: $modified-theme-color));
  $modified-theme: map-merge($modified-theme, (background: $background-palette));

  @return $modified-theme;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-sample-primary: mat.define-palette(mat.$light-blue-palette, A700);
$theme-sample-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-sample-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// Background palette for dark themes.
$mat-dark-theme-background: (
  text: white,
  status-bar: black,
  app-bar:    #282C34,
  background: #1c2025,
  hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
  card:        #282C34,
  dialog:      #282C34,
  disabled-button: rgba(white, 0.12),
  raised-button: mat.get-color-from-palette(mat.$grey-palette, 800),
  focused-button: black,
  selected-button: mat.get-color-from-palette(mat.$grey-palette, 900),
  selected-disabled-button: mat.get-color-from-palette(mat.$grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: mat.get-color-from-palette(mat.$grey-palette, 700),
  disabled-list-option: black,
  tooltip: mat.get-color-from-palette(mat.$grey-palette, 700),
  
);
$theme-sample-theme: mat.define-dark-theme((
  color: (
    primary: $theme-sample-primary,
    accent: $theme-sample-accent,
    warn: $theme-sample-warn,
  ),
));

$theme-sample-theme: modify-background($theme-sample-theme, $mat-dark-theme-background);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($theme-sample-theme);
@include mat.all-component-themes($theme-sample-theme);

// @mixin candy-carousel-color($config-or-theme) {
//   // Extract the color configuration in case a theme has been passed.
//   // This allows consumers to either pass a theme object or a color configuration.
//   $config: mat-get-color-config($config-or-theme);
//   // Extract the palettes you need from the theme definition.
//   $primary: map-get($config, primary);
//   $accent: map-get($config, accent);

//   // Define any styles affected by the theme.
//   .candy-carousel {
//     // Use mat-color to extract individual colors from a palette.
//     background-color: mat-color($config);
//     border-color: mat-color($config, A400);
//   }
// }

// .mat-snack-bar-container.error {
//   background-color: mat.get-color-from-palette(mat.$red-palette, A400);
//   color: white;
// }

// .mat-snack-bar-container.ok {
//   background-color: mat.get-color-from-palette(mat.$green-palette, A700);
//   color: white;
// }


body {
  --warning-container-color: #5a3505;
  --error-container-color: rgb(80, 38, 38);
  --ok-container-color: rgb(58, 78, 54);
  --advise-container-color: rgb(64, 54, 25);

  --warning-font-color: #ca7304;
  --error-font-color: #8dce7d;
  --ok-font-color: #ce7d7d;
  --advise-font-color: #fdd663;
  --mat-sidenav-scrim-color: #111316b8;
}

.mat-mdc-snack-bar-container.warning {
  --mdc-snackbar-container-color: var(--warning-container-color);
}
.mat-mdc-snack-bar-container.error {
  --mdc-snackbar-container-color: var(--error-container-color);
}
.mat-mdc-snack-bar-container.ok {
  --mdc-snackbar-container-color: var(--ok-container-color);
}
.mat-mdc-snack-bar-container.advise {
  --mdc-snackbar-container-color: var(--advise-container-color);
}
.error {
  color:mat.get-color-from-palette(mat.$red-palette, A400);
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.sticky {
  mat-tab-header {
    position: sticky !important;
    top: 0;
    background-color: #282C34;
    z-index: 2;
  }
}

.mdc-button {
  letter-spacing: 0px !important;
}

.mat-mdc-tab {
  letter-spacing: 0px !important;
}

@media (max-width: 600px) {

  .mat-expansion-panel-header {
    padding: 0 8px !important;
  }

  .mat-expansion-panel-body {
    padding: 0 12px 12px 12px !important;
  }

  .mat-vertical-content-container {
      margin-left: 16px !important;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header, .mat-vertical-stepper-header {
    padding: 24px 8px !important;
  }

  .mat-vertical-content {
      padding: 0 8px 8px 8px !important;
  }
}

.mdc-button__label {
  text-align: left;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: 16px;
}

mat-card.full-height {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box
}